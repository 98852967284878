import React from 'react';

import styles from './jumbotron.module.scss';

const Jumbotron = ({
  headline1, headline2, subtitle, children,
}) => {
  return (
    <div className="jumbotron">
      <div className={styles.col}>
        <h1 className={styles.headLine}>
          {headline1}
          {headline2 && (
          <>
            <br />
            {headline2}
          </>
          )}
        </h1>
        <p className={styles.subtitles}>
          {subtitle}
        </p>
      </div>
      {children && (
      <div className={styles.col}>
        {children}
      </div>
      )}
    </div>
  );
};

export default Jumbotron;
