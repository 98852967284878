import React from 'react';

import styles from './button.module.scss';

const StartNowButton = ({ cta, url }) => {
  return (
    <a href={url} className={styles.cta}>
      <span>{cta}</span>
    </a>
  );
};

export default StartNowButton;
